export const formatNumber = value => {
  if (value === null || value === undefined || typeof value === 'boolean') {
    return value
  }

  const numberValue = Number(value)

  if (Number.isNaN(numberValue)) {
    return value
  }

  if (Number.isInteger(numberValue)) {
    return numberValue
  }

  return Number(numberValue.toFixed(2))
}
