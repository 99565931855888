import { createTheme, Divider, Grid, TableCell, TableRow, ThemeProvider } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { Fragment, useEffect, useState } from 'react'
import { themeOptions } from '../../themes/main'
import { formatNumber } from '../../utils/formatNumber'

const expandableRow = ({ rowMeta, columns, excludedDataKeys, formDefinition, data }) => {
  const visibleColumnNames = columns.map(column => column.name)

  const expandableFields = formDefinition.fields.filter(
    field =>
      field.name &&
      !visibleColumnNames.includes(field.name) &&
      !excludedDataKeys.includes(field.name),
  )

  const { fieldArrayFields, nonFieldArrayFields } = expandableFields.reduce(
    (acc, field) => {
      if (field.component_type === 'FieldArray') {
        acc.fieldArrayFields.push(field)
      } else {
        acc.nonFieldArrayFields.push(field)
      }
      return acc
    },
    { fieldArrayFields: [], nonFieldArrayFields: [] },
  )

  const rowData = data[rowMeta.dataIndex]

  return (
    <TableRow sx={{ backgroundColor: '#f9f9f9' }}>
      <TableCell colSpan={columns.length + 1}>
        <Grid container spacing={2}>
          {nonFieldArrayFields.map(field => (
            <Grid key={`${field.label}-${rowMeta.dataIndex}`} item xs={6}>
              <b>{field.label}:</b> {formatNumber(rowData[field.name]) ?? 'N/A'}
            </Grid>
          ))}
        </Grid>
        {fieldArrayFields.map(field => (
          <Fragment key={field.name}>
            <Divider sx={{ margin: '8px 0' }} />
            <b>{field.label}:</b> {rowData[field.name] ?? 'N/A'}
          </Fragment>
        ))}
      </TableCell>
    </TableRow>
  )
}

const tableTheme = createTheme({
  ...themeOptions,
  components: {
    ...themeOptions.components,
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #e0e0e0',
        },
      },
    },
  },
})

export const ExpandableDataTable = ({
  formDefinition,
  data,
  columns,
  excludedDataKeys,
  customOptions,
  isAllExpanded = false,
}) => {
  const [expandedRows, setExpandedRows] = useState([])

  useEffect(() => {
    if (isAllExpanded) {
      const allRows = data.map((_, index) => index)
      setExpandedRows(allRows)
    }
  }, [data, isAllExpanded])

  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    sort: false,
    selectableRows: 'none',
    enableNestedDataAccess: '.',
    pagination: false,
    ...customOptions,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) =>
      expandableRow({ rowData, rowMeta, columns, excludedDataKeys, formDefinition, data }),
    isRowExpandable: () => true,
    rowsExpanded: expandedRows,
  }

  return (
    <ThemeProvider theme={tableTheme}>
      <MUIDataTable columns={columns} data={data} options={options} />
    </ThemeProvider>
  )
}
